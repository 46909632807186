.sidebar {
  flex: 1;
  border-right: 0.5px solid lightgray;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.19);
  text-transform: none !important; /* Overwrite any other text-transform rules */

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .logo {
      padding: 10px;
      margin-bottom: 50px;
      font-size: 30px;
      font-weight: bold;
      color: #0505057a;
      align-items: center;
      justify-content: center;
    }
  }

  hr {
    height: 0;
    border: 0.5px solid lightgray;
  }

  .center {
    padding-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;

    ul {
      list-style: none;
      padding: 0;

      .title {
        font-size: 15px;
        font-weight: bold;
        color: #999;
      }

      li {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 10px;
        cursor: pointer;

        &:hover {
          background-color: #e105644f;
        }
        &.active{
          background-color: #e105644f ;
        }

        .icon {
          font-size: 25px;
          color: #e10564;
        }

        span {
          font-size: 17px;
          font-weight: bold;
          color: #888;
          margin-left: 10px;
        }
      }
    }


    ul li {
      text-transform: none !important; 
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid lightgray;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }

      &:nth-child(2) {
        background-color: #333;
      }
    }
  }
  .MuiButtonBase-root,
.MuiButton-root {
  text-transform: none !important; 
}

}
