.datatable{
  // height: 600px;
  // padding: 20px;
  .cellAction{
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton{
      padding: 2px 5px;
      border-radius: 5px;
      color:darkblue;
      border: 1px solid rgba(0, 0, 139, 0.267);
      cursor: pointer;
  
}
    .deleteButton{
      padding: 2px 5px;
      border-radius: 5px;
      color:crimson;
      border: 1px solid rgba(220, 20, 60, 0.226);
  
}
    .editButton{
      padding: 2px 5px;
      border-radius: 5px;
      color:rgb(20, 163, 220);
      border: 1px solid rgba(20, 163, 220, 0.276);
  
}
  }
}