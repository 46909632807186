// register.scss
.register {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100vh; /* 100% of viewport height */
  background-image: url(../../register.jpg);

  .newContainer {
    width: 40%;

    h1 {
      color: #e10564;
      font-size: 30px;
      text-align: center; /* Center text */
    }

 
    .bottom {
      display: flex;
      flex-direction: column; /* Align children vertically */
      
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.822);

      .right {
        flex: 2;
        padding: 10px;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .formInput {
            width: 100%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: 1px solid #e10564;
              background-color: white;
            }
          }
        }
      }
    }
  }
}
