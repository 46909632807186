.financetable {
  .opening {
    background-color: rgba(0, 128, 0, 0.1); // Green background for opening records
  }

  .closing {
    background-color: rgba(255, 0, 0, 0.1); // Red background for closing records
  }

  .tableCell {
    // Your existing table cell styles here
  }
}
