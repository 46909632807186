.reporttable{
  .status{
    padding: 5px;
    border-radius: 5px;

    &.Approved{
      color:green;
      background-color: rgb(0, 128,0, 0.151);
    }
    &.Pending{
      color:rgb(249, 190, 40);
      background-color: rgb(189,189,0, 0.103);
    }
    &.Paid{
      color:rgb(156, 84, 1);
      background-color: rgb(189,189,0, 0.103);
    }
  
}
}