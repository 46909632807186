.table{
  .status{
    padding: 5px;
    border-radius: 5px;

    &.Approved{
      color:green;
      background-color: rgb(0, 128,0, 0.151);
    }
    &.Pending{
      color:goldenrod;
      background-color: rgb(189,189,0, 0.103);
    }
  
}
}