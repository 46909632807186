.new {
  display: flex;
  width: 100%;

  .newContainer {
    flex: 6;

    .top,
    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      border-radius: 10px;
      margin: 20px;
      display: flex;

      h1 {
        color: lightgray;
        font-size: 20px;
      }

      .right {
        flex: 2;
        padding: 10px;

        form {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          .formInput {
            width: 48%;

            label {
              display: flex;
              align-items: center;
              gap: 10px;

              .icon {
                cursor: pointer;
              }
            }

            input {
              width: 100%;
              padding: 5px;
              border: none;
              border-bottom: 1px solid gray;
            }
          }

          button {
            width: 150px;
            padding: 10px;
            border: none;
            background-color: #e10564;
            color: white;
            font-weight: bold;
            cursor: pointer;
            margin-top: 10px;
          }

          input[type="date"],
          input[type="time"] {
            width: 100%;
            padding: 8px;
            border: none;
            border-bottom: 1px solid gray;
            font-size: 16px;
            border-radius: 0;
            background-color: transparent;
            color: #333;
            transition: border-color 0.3s ease;

            &:focus {
              outline: none;
              border-color: #007bff;
            }
          }

          input[type="date"]::-webkit-calendar-picker-indicator,
          input[type="time"]::-webkit-calendar-picker-indicator {
            display: none;
          }
        }
      }
    }
  }
}
