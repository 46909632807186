/* cart.scss */

.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 12px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 12px 1px rgba(201, 201, 201, 0.47);
  border-radius: 12px;
  padding: 12px;

  .orderSummary {
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .orderDetails {
      margin-bottom: 12px;
      color: gray;
      font-size: 12px; /* Set font size to 12px */
    }

    .orderItems {
      display: flex;
      flex-direction: column; /* Change flex-direction to column */
      justify-content: space-between;
      margin-bottom: 12px;
      overflow-y: auto; /* Enable vertical overflow with scroll if necessary */
      max-height: 200px; /* Set a max height for the container */

      .itemContent,
      .itemAmount {
        p {
          font-size: 12px; /* Set font size to 12px */
          margin: 3px 0;
        }
      }
    }

    .orderTotals {
      p {
        font-size: 12px; /* Set font size to 12px */
        margin: 3px 0;
      }
    }
  }

  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is above other content */
  }
  
  .dialog {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(2, 0, 0, 0.3);
    max-width: 450px;
  
    h2 {
      margin-top: 0;
      font-size: 24px; /* Increase font size for title */
      margin-bottom: 20px; /* Increase margin bottom for title */
    }
  
    p {
      margin: 0;
      font-size: 16px; /* Increase font size for paragraphs */
    }
  
    label {
      display: block;
      margin-top: 10px;
      font-size: 16px; /* Increase font size for labels */
    }
  
    input {
      // width: 100%;
      padding: 8px;
      margin-top: 5px;
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 16px; /* Increase font size for inputs */
    }
  
    button {
      margin-top: 10px;
      padding: 8px 16px;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: 18px; /* Increase font size for buttons */
      transition: background-color 0.3s; /* Smooth transition for background color */
  
      &:hover {
        background-color: #0056b3; /* Darker shade of blue on hover */
      }
    }
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Increase font size for close button */
    color: #333;
  }
  

  .update,
  .cancel-order {
    background-color: #e10564; /* Blue color */
    color: #fff; /* White text color */
    padding: 10px 20px; /* Padding for the buttons */
    border: none; /* Remove button border */
    border-radius: 5px; /* Rounded corners */
    margin-top: 10px; /* Margin to separate buttons */
    cursor: pointer; /* Cursor style */
    transition: background-color 0.3s; /* Smooth transition for background color */
    margin-right: 10px;

    &:hover {
      background-color: #e10564; /* Darker shade of blue on hover */
    }

    &:focus {
      outline: none; /* Remove focus outline */
    }
  }

  .card-container {
    display: flex;
    flex-direction: column; 
    overflow-y: auto; 
    max-height: 600px; 
  }
}
